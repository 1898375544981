import React, {
  type FunctionComponent, lazy, Suspense, useMemo, useState,
} from 'react';

import { useInView } from '@Hooks';

type HydrateWhenInViewport2Props = IntersectionObserverInit & {
  className?: string;
  children: React.ReactNode;
  wrapper?: React.ElementType;
};

const isServer = typeof window === 'undefined';

export const HydrateWhenInViewport2: React.FC<HydrateWhenInViewport2Props> = ({
  threshold = 0,
  rootMargin = '200px',
  root,
  className,
  children,
  wrapper: Outer = 'div',
}) => {
  if (isServer) {
    return (
      <Outer className={className}>
        {children}
      </Outer>
    );
  }

  const [ isHydrated, setShouldHydrate ] = useState(false);
  // eslint-disable-next-line no-spaced-func
  const deferred = useMemo<{ promise: Promise<void>; resolve: () => void }>(() => {
    let resolve: () => void | undefined;
    const promise = new Promise<void>((r) => {
      resolve = r;
    });

    return { promise, resolve: resolve! };
  }, []);

  const Inner = useMemo(() => lazy<FunctionComponent>(async () => {
    if (!isHydrated) {
      await deferred.promise;
    }

    return { default: () => <React.Fragment>{children}</React.Fragment> };
  }), [ deferred, children ]);

  const inViewRef = useInView(
    (isInView) => {
      if (isInView) {
        deferred.resolve();
        setShouldHydrate(true);
      }
    },
    {
      disabled: isHydrated,
      triggerOnlyOnce: true,
      root,
      rootMargin,
      threshold,
    },
  );

  return (
    <Outer
      ref={inViewRef}
      className={className}
    >
      <Suspense fallback="not loaded or hydrated">
        <Inner />
      </Suspense>
    </Outer>
  );
};
